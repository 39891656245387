/** # webpack 直接入參 */
const GIT_ENV = process.env.GIT_ENV;
/**
 * # 環境變數
 *
 * ## 唯讀
 */
export const ENV = {
    NEXT_PUBLIC_TARGET_AGENT_PRODUCT: process.env.NEXT_PUBLIC_TARGET_AGENT_PRODUCT,
    NEXT_PUBLIC_TARGET_PAGES: process.env.NEXT_PUBLIC_TARGET_PAGES,
    ...GIT_ENV,
};
