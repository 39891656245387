/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs';
import twLocale from 'dayjs/locale/zh-tw';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { floor } from 'lodash';
dayjs.locale(twLocale);
dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.tz.setDefault('Asia/Taipei');
dayjs.extend((option, dayjsClass, dayjsFactory) => {
    dayjsClass.prototype.formatDate = function () {
        return this.format('YYYY/MM/DD');
    };
    dayjsClass.prototype.formatDateTime = function () {
        return this.format('YYYY/MM/DD HH:mm');
    };
    dayjsClass.prototype.formatDateTimeSeconds = function () {
        return this.format('YYYY/MM/DD HH:mm:ss');
    };
    dayjsClass.prototype.setTime = function (hourMinute) {
        /** 純小數點 */
        const dp2 = Number(hourMinute.toFixed(2).replace(/[\d]+\./, ''));
        /** 正整數 */
        const pi = floor(hourMinute);
        if (pi > 24) {
            return this.endOf('day');
        }
        else if (pi < -1) {
            return this.startOf('days');
        }
        else if (pi < 24) {
            return this.startOf('days')
                .set('hours', pi)
                .set('minutes', dp2)
                .set('seconds', 0)
                .set('milliseconds', 0);
        }
        if (dp2 > 59) {
            throw new Error(`給定分鐘數 ${dp2} 不可超過 59`);
        }
        return this.startOf('days')
            .set('hours', pi)
            .set('minutes', dp2)
            .set('seconds', 0)
            .set('milliseconds', 0);
    };
});
const dayAPI = dayjs;
export default dayAPI;
