import { isError } from 'lodash';
import { z } from 'zod';
export class StrategyCenterRequestError extends Error {
    /** 前端與後端事先約定好的 Error Response Schema */
    static schema = z.object({
        data: z.object({
            message: z.string(),
        }),
    });
    static from = (error) => {
        try {
            return new StrategyCenterRequestError(StrategyCenterRequestError.schema.parse(error).data.message, { cause: error });
        }
        catch (err) {
            if (isError(error)) {
                return new StrategyCenterRequestError(error.message, { cause: error });
            }
            return new StrategyCenterRequestError(`意外錯誤`, { cause: err });
        }
    };
}
