import { isError, map } from 'lodash';
import { Fetcher } from 'openapi-typescript-fetch';
import { UrluOfQuery } from '~/modules/SDK/app/urlu/UrluOfQuery';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
/**
 * @example
 *   //
 *   export class StrategyDevAPI extends Urlu2<StrategyDevAPISwagger> {}
 *
 *   const apirc = {
 *     strategy: new StrategyDevAPI({
 *       baseUrl: `https://dev.api.strategy-center.futures-op.com`,
 *     }),
 *   }
 */
export class Urlu2 {
    options;
    // public options: { baseUrl?: string; middlewares?: Middleware[] } = {}
    static is = {
        errorResponse: function isErrorResponse(json) {
            if (isError(json) && 'data' in json) {
                return true;
            }
            return false;
        },
    };
    constructor(options) {
        this.options = options;
        this.configureClient();
    }
    /**
     * 前端執行 http-request 送往後端 Restful API endpoints
     *
     * @example
     *   //
     *   // 使用命令式呼叫
     *   return (
     *     <Button
     *       onClick={event => {
     *         apis.http('post', '/api/users/login').request({
     *           account: values.username,
     *           password: values.password,
     *         })
     *       }}
     *     />
     *   )
     *
     *   //
     *   // 使用 useSWR (react-hook)
     *   const useMe = () => {
     *     const me = apis
     *       .http('get', '/api/users/{account}')
     *       .useSWR(
     *         { account },
     *         { preventRequestIf: () => !pageStore.apis.options.jwt || !account },
     *       )
     *   }
     */
    http(method, path) {
        const client = this._methods[`${method}${path}`];
        if (client) {
            return client;
        }
        return (this._methods[`${method}${path}`] = this.createRequest(method, path));
    }
    /** 配置 Middleware 或 jwt、baseUrl 等等的基本組態 */
    configureClient() {
        this._client.configure({
            baseUrl: this.options.baseUrl,
            use: [...map(this.options.middlewares), ...map(this._middlewares)],
        });
    }
    _client = Fetcher.for();
    _middlewares = {
        withJwt: async (url, init, next) => {
            const jwt = localStorage.getItem('jwt') || localStorage.getItem(`${fr_agents.agentProduct}.jwt`);
            init.headers.set('Authorization', `Bearer ${jwt || '__EMPTY__'}`);
            const response = await next(url, init);
            return response;
        },
    };
    /** cache for created methods */
    _methods = {};
    /** 內部用來建立會有附帶 react-hook http 函式 */
    createRequest(method, path) {
        const openapi = this._client.path(path).method(method).create();
        function fetcherCarrier(...params) {
            return openapi(...params).then(res => res.data);
        }
        const urluQuery = new UrluOfQuery(fetcherCarrier, {
            namespace: `${method.toString()} ${path.toString()}`,
        });
        fetcherCarrier.useSWR = urluQuery.useSWR;
        fetcherCarrier.useAsync = urluQuery.useAsync;
        fetcherCarrier.useAsyncFn = urluQuery.useAsyncFn;
        fetcherCarrier.request = urluQuery.fetch;
        return fetcherCarrier;
    }
}
