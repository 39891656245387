import { initializeApp } from 'firebase/app';
import { FacebookAuthProvider, GoogleAuthProvider, getAuth } from 'firebase/auth';
import { collection, doc, getDoc, getFirestore, } from 'firebase/firestore';
import { expectType } from '~/utils/tsd';
const app = initializeApp({
    appId: 'futures-ai',
    apiKey: 'AIzaSyCn5dseBaKD4ESxCFdRvqNasYVVsDmtNs4',
    authDomain: 'futures-ai.firebaseapp.com',
    databaseURL: 'https://futures-ai.firebaseio.com',
    projectId: 'futures-ai',
    storageBucket: 'futures-ai.appspot.com',
    messagingSenderId: '463877906717',
});
/** 這是底層 firebase/firestore 官方實例 */
const db = getFirestore(app);
export const FrFirebase = {
    facebookProvider: new FacebookAuthProvider(),
    googleProvider: new GoogleAuthProvider(),
    /** 這是底層 firebase/auth 官方實例 */
    auth: getAuth(app),
    /**
     * 使用此方法能夠自動推斷出型別
     *
     * ## 擴充自動類型推斷
     *
     * - {@link FrFirebaseTypes.CollectionPath}
     * - {@link FrFirebaseTypes.CollectionDataMapp}
     *
     * @example
     *   //
     *   FrFirebase.getCollectionRef('san_daily_price_line')
     *   FrFirebase.getCollectionRef('symbol_watch_list')
     *
     * @example
     *   //
     *   await getDocs(
     *     query(
     *       FrFirebase.getCollectionRef('idea_posts'), //💡
     *       orderBy('publish_date', 'desc'),
     *       limit(cursor),
     *     ),
     *   )
     */
    getCollectionRef(collectionPath) {
        return collection(db, collectionPath);
    },
    /**
     * 使用此方法能夠自動推斷出型別
     *
     * ## 擴充自動類型推斷
     *
     * - {@link FrFirebaseTypes.CollectionPath}
     * - {@link FrFirebaseTypes.CollectionDataMapp}
     *
     * @example
     *   //
     *   onSnapshot(
     *     FrFirebase.getDocRef('symbol_watch_list', uid), //💡
     *     docSnap => setSymbolList(docSnap.data()),
     *   )
     *
     * @example
     *   //
     *   onSnapshot(
     *     FrFirebase.getDocRef('san_daily_price_line', selectedDate), //💡
     *     docSnap => {
     *       const data = docSnap.data()
     *       useFivePricesStore.setState({ prices: data?.price ?? [] })
     *       setPrices(data?.price ?? [])
     *     },
     *   )
     */
    getDocRef(collectionPath, docPath) {
        return doc(db, collectionPath, docPath || '__MISSING_DOC_PATH__');
    },
    // messaging: getMessaging(app),
    // async getMessagingToken() {
    //   return await getToken(FrFirebase.messaging, {
    //     vapidKey:
    //       'BBBJjeNpdomXx8E-IwO34ei2C9uzE_8XtUybrCKgrd4a8tfVtU1tri8A1s-PccgykC5yIsvZBU08aNExN1GaX_o',
    //   })
    //     .then(currentToken => {
    //       if (currentToken) {
    //         console.info('OK, 将令牌发送到您的服务器，并在必要时更新用户界面。')
    //         // ...
    //       } else {
    //         // Show permission request UI
    //         console.info('BAD, 没有可用的注册令牌。请求生成一个权限。')
    //         // ...
    //       }
    //       return currentToken
    //     })
    //     .catch(err => {
    //       return Promise.reject(
    //         new Error('An error occurred while retrieving token. ', { cause: err }),
    //       )
    //     })
    // },
    requestMessagingPermission() {
        console.log('Requesting permission...');
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
            }
        });
    },
};
/* istanbul ignore next */
function TypingTesting() {
    async function Test1() {
        const ownDoc = await getDoc(FrFirebase.getDocRef('symbol_watch_list', 'uid'));
        expectType(ownDoc.data());
    }
}
